import {Component, OnChanges, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {

  showForm = true;

  solicitudInformacion = new FormGroup({
    nombre: new FormControl('', Validators.compose([
      Validators.required
    ])),
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    mensaje: new FormControl('', Validators.compose([
      Validators.required,
    ])),
    aceptacion: new FormControl(false, Validators.compose([
      Validators.requiredTrue,
      Validators.required
    ]))
  });

  constructor( private http: HttpClient) {
  }

  ngOnInit() {}

  enviardatos() {
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Access-Control-Allow-Origin': '*',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
      const url = 'https://emailserver.editorialglosa.es/mensaje';
      console.log(this.solicitudInformacion.value);
      this.http.post(url, this.solicitudInformacion.value, httpOptions).subscribe(() => {
        this.solicitudInformacion.reset();
        this.showForm = false;
      });
  }

  ngOnChanges() {
    //console.log('ngonchanges');
    //console.log(this.solicitudInformacion.value.nombre);
  }

}
