import { NgModule } from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ComponentsModule} from './components/components.module';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    title: 'Editorial Glosa',
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    title: 'Formación Editorial Glosa',
    path: 'formacion',
    loadChildren: () => import('./formacion/formacion.module').then( m => m.FormacionPageModule)
  },
  {
    title: 'Expertos Editorial Glosa',
    path: 'expertos',
    loadChildren: () => import('./expertos/expertos.module').then( m => m.ExpertosPageModule)
  },
  {
    title: 'Aviso legal Editorial Glosa',
    path: 'aviso-legal',
    loadChildren: () => import('./aviso-legal/aviso-legal.module').then( m => m.AvisoLegalPageModule)
  },
  {
    title: 'Política de privacidad Editorial Glosa',
    path: 'politica-privacidad',
    loadChildren: () => import('./politica-privacidad/politica-privacidad.module').then( m => m.PoliticaPrivacidadPageModule)
  },
  {
    title: 'Política de cookies Editorial Glosa',
    path: 'politica-cookies',
    loadChildren: () => import('./politica-cookies/politica-cookies.module').then( m => m.PoliticaCookiesPageModule)
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    ComponentsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
