import {
  Component, Input
} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {ScreensizeService} from './services/screensize.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],

})
export class AppComponent {
  constructor( private platform: Platform, private screenSizeService: ScreensizeService,
               private menuController: MenuController,
               ) {}
  close(){
    this.menuController.close().then( cerrado => {
      if (!cerrado) {
        this.screenSizeService.onResize(this.platform.width());
      }
    });
  }
}
