import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenuComponent} from './menu/menu.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [MenuComponent, FooterComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [MenuComponent, FooterComponent]
})
export class ComponentsModule { }
