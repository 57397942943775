import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreensizeService {

  private isDesktop = new BehaviorSubject<boolean>(false);

  constructor() { }

  onResize(size) {
    console.log('ancho: ' + size);
    if (size < 568) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }

  idDesktopView() {
    //console.log('pantalla');
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}
