import {Component, Input, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {ScreensizeService} from '../../services/screensize.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  @Input() menu = 'menu';

  navigate =
    [
      {
        title : 'Sobre nosotros',
        url   : '/home',
        icon  : 'apps',
        ocultar : 'ion-hide-lg-down'
      },
      {
        title : 'Formación',
        url   : '/formacion',
        icon  : 'book',
        ocultar : 'ion-hide-lg-down'
      },
      {
        title : 'Expertos en comunicación',
        url   : '/expertos',
        icon  : 'brush',
        ocultar : 'ion-hide-md-down'
      },
      /*{
        title : 'Contactar',
        url   : '/home#contactar',
        fragment: 'contactar',
        icon  : 'brush',
        ocultar : 'ion-hide-lg-down'
      }*/
    ];

  isDesktop: boolean;
  menuSeleccionado: string;

  ventana: number;



  constructor(
    private menuController: MenuController,
    private screenSizeService: ScreensizeService,
    protected router: Router,
    private platform: Platform,
  ) {
    this.initializeApp();

    this.menuSeleccionado = this.router.url;

    this.screenSizeService.idDesktopView().subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }
  ngOnInit() {
  }

  initializeApp() {
    this.menuSeleccionado = this.router.url;

    this.platform.ready().then( () => {
      this.ventana = this.platform.width();
      this.screenSizeService.onResize(this.platform.width());
    });
  }

  close(){
    this.menuController.close().then( cerrado => {
      if (!cerrado) {
        this.screenSizeService.onResize(this.platform.width());
      }
    });
  }
}
